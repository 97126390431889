 jQuery(window).load(function() {
     var top = window.pageYOffset || document.documentElement.scrollTop;
     var sd_header = document.getElementById('sd-header');
     if (top <= 0) sd_header.className = sd_header.className.replace(' scrolled', '');
 });

// init sidr menu
jQuery(".sd-responsive-menu-toggle a").sidr({
    name: "sidr-main",
    source: ".sd-menu-nav",
    side: "right",
    renaming: false,
    displace: false,
    onOpen: function () {
        jQuery(".sd-responsive-menu-close").toggleClass("sd-resp-menu-active");
    },
    onClose: function () {
        jQuery(".sd-responsive-menu-close").removeClass("sd-resp-menu-active");
    }
});

jQuery('.sidr-class-submenu-button').click(function(e) {
    e.preventDefault();
    $(this).next().slideToggle();
});
jQuery(".sidr-inner").prepend('<span class="sd-responsive-menu-close"><i class="fa fa-times"></i></span>');
jQuery(".sidr-class-mega-menu-item-has-children").each(function (e) {
    jQuery(this).append('<span class="sidr-chevron"><i class="fa fa-chevron-right"></i></span>');
});
jQuery(".sidr-chevron").on("click touchstart", function () {
    var e = jQuery(this).prev("ul");
    var t = e.is(":visible") ? '<i class="fa fa-chevron-right"></i>' : '<i class="fa fa-chevron-down"></i>';
    jQuery(this).html(t);
    e.toggle();
    jQuery(this).toggleClass("sd-menu-active");
    return false;
});
jQuery(".sd-responsive-menu-close").click(function () {
    jQuery.sidr("close", "sidr-main");
    return false;
});

// Scroll to #
$(function () {
    var heightMenu = $('#sd-header').height();
    $('a[href*="#"]:not([href="#"])').click(function () {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            jQuery.sidr("close", "sidr-main");
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html, body').animate({
                    scrollTop: target.offset().top - heightMenu
                }, 1000);
                return false;
            }
        }
    });
});
function init() {
    window.addEventListener("scroll", function () {
        var top = window.pageYOffset || document.documentElement.scrollTop;
        /* Add opacity to header if page is scrolled */
        var sd_header = document.getElementById('sd-header');
        if (sd_header.className.split(' ').indexOf('scrolled') < 0 && top > 0) {
            sd_header.className += " scrolled";
        }
        if (top <= 0) sd_header.className = sd_header.className.replace(' scrolled', '');
    });

    var heightMenu2 = $('#sd-header').height();
    console.log(heightMenu2);
    if (window.location.hash) {
        $('html, body').animate({
            scrollTop: $(window.location.hash).offset().top - heightMenu2
        }, 0);
    }
}

window.onload = init;
